import api from './api';

export async function login(email, senha, remember) {
    const ret = await api.post(`/auth/login`, {email, senha, remember});
    return ret.data;
}

export async function getUsuarioToken(token) {
    const ret = await api.get('/auth/getUsuarioToken', { params: { token }});
    return ret.data;
}
