<script setup>
//import { useLayout } from '@/layout/composables/layout';
import { computed } from 'vue';

//const { layoutConfig } = useLayout();

const logoUrl = computed(() => {
    //console.log(layoutConfig);
    return `/images/logomizune.png`;
    //return `layout/images/${layoutConfig.darkTheme.value ? 'logo-white' : 'logo-dark'}.svg`;
});
</script>

<template>
    <div class="layout-footer">
        <img :src="logoUrl" alt="Logo" height="20" class="mr-2" />
        by
        <span class="font-medium ml-2">Mizune.com.br</span>
    </div>
</template>
<style lang="scss" scoped></style>
