import { getMenus } from '@/services/menus.service';
import AppLayout from '@/layout/AppLayout.vue';
import store from '@/store'; 
import { getSistemaSelecionado } from '@/util';

export const getRotas = async () => {
    let todasAsRotas = [
        {
            name: 'AppLayout',
            path: '/',
            component: AppLayout,
            meta: {
                requireAuth: true,
            },
            children: [],
        },
        {
            path: '/fin/catalogovenda/:uuid',
            component: () => import('@/views/fin/CatalogoVenda.vue')
        },
        {
            path: '/teste',
            name: 'testePage',
            component: () => import('@/views/testePage.vue')
        },
        {
            path: '/errorpage',
            name: 'errorPage',
            component: () => import('@/views/errorPage.vue')
        },
        {
            path: '/acessonegado',
            name: 'accessDenied',
            component: () => import('@/views/auth/AccessDenied.vue')
        },
        {
            path: '/login',
            name: 'loginPage',
            component: () => import('@/views/auth/LoginPage.vue')
        },
        {
            path: '/logout',
            name: 'logoutPage',
            component: () => import('@/views/auth/LogoutPage.vue')
        },
        {
            path: '/:catchAll(.*)',
            name: 'catchall',
            component: () => import('@/views/notFound.vue')
        }
    ];
    const cdsistema = getSistemaSelecionado();
    if (!cdsistema) {
        return todasAsRotas;
    }    
    let menuscompath = [];
    try {
        //console.log('getMenus', cdsistema);
        menuscompath = await getMenus(cdsistema, true);
    } catch (error) {
        console.log(error);
    }

    let userAuthorities = [];
    if (store.state.usuario) {
        userAuthorities = store.state.usuario.authorities.map(auth => auth.authority);
    }
    
    //console.log('userAuthorities', userAuthorities);
    menuscompath.forEach((item) => {
        //const hasPermission = item.permissoes.some(permissao => userAuthorities.includes(permissao.nmpermissao));
        const hasPermission = !item.requireauth || item.permissoes.some(permissao => userAuthorities.includes(permissao.nmpermissao));
        let target = null;
        if (!item.requireauth) {
            target = todasAsRotas;
        } else {
            target = todasAsRotas[0].children;
        }
        if (hasPermission) {
            target.push({
                path: item.path,
                name: item.nmmenu,
                meta: {
                    requireAuth: item.requireauth,
                    grants: item.permissoes.map(permissao => permissao.nmpermissao),
                },
                component: () => import(`@/views/${item.componente}.vue`),
            });
        }
    });
    // Inclusão da rota de alteração de senha dentro do Nested children
    todasAsRotas[0].children.push({
        path: '/alterarsenha',
        name: 'alterarSenha',
        meta: {
            requireAuth: true,
        },
        component: () => import(`@/views/pdr/usuariosAlterarSenha.vue`),
    });
    console.log('todasAsRotas', todasAsRotas);
    return todasAsRotas;
};

export const updateDynamicRoutes = async (router) => {
    router.getRoutes().forEach((route) => {
        router.removeRoute(route.name);
    });
    const rotas = await getRotas();
    rotas.forEach(route => {
        router.addRoute(route);
    });
    return;
};