<template>
    <Menu :popup="true" ref="menu" :model="itensMenuUsuario" class="border-1">
        <template #start>
            <button v-ripple
                class="relative overflow-hidden w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround border-1">
                <Avatar image="/images/people.png" class="mr-2" shape="circle" />
                <span class="inline-flex flex-column">
                    <span class="font-bold">{{ store.state.usuario.nome }}</span>
                    <span class="text-sm">{{ licenciamento }} </span>
                </span>
            </button>
        </template>
        <!-- <template #end>
            <TieredMenu :model="itensMenuUsuario">

            </TieredMenu>
        </template> -->

    </Menu>
</template>
<script setup>
import { ref, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { refreshDynamicRoutes } from '@/router';
import { useStore } from 'vuex';
import { getSistemaSelecionado, getUsuarioIsMaster } from '@/util';
import { updateSistemaSelecionado } from '@/services/usuarios.service';

const store = useStore();
const router = useRouter();
const menu = ref('menu');
const licenciamento = ref('');
const itensMenuUsuario = ref([]);

onMounted(async () => {
    if (store.state.usuario) {
        const cdsistema = getSistemaSelecionado();
        const isUsuarioMaster = store.state.usuario.authorities.some(a => a.authority == 'MASTER');
        if (isUsuarioMaster) {
            licenciamento.value = 'Usuário Master';
        } else {
            const licenca = store.state.usuario.licencas.find(licenca => licenca.cdsistema === cdsistema);
            licenciamento.value = `Licenciado até ${licenca?.dtvalidade}`;
        }
    }

    atualizarMenusDoUsuario();

});

const toggle = (e) => {
    menu.value.toggle(e);
};

defineExpose({
    toggle
});

const loadMenus = inject('loadMenus');

const cdsistema = ref(getSistemaSelecionado());

const mudarSistema = async (pcdsistema) => {
    //console.log('mudarSistema', pcdsistema);
    await updateSistemaSelecionado(store.state.usuario.cdusuario, pcdsistema);
    localStorage.setItem('sistemaselecionado', pcdsistema);
    loadMenus();
    await refreshDynamicRoutes();
    const listaSistemas = store.state.listaSistemas;
    const sistema = listaSistemas.find(s => s.cdsistema == pcdsistema);
    //console.log('listaSistemas', listaSistemas);
    router.push(sistema.nmcaminhohome);
    cdsistema.value = pcdsistema;
}


const atualizarMenusDoUsuario = () => {
    itensMenuUsuario.value = [];
    itensMenuUsuario.value.push({
        label: 'Alterar minha senha',
        icon: 'pi pi-fw pi-lock',
        command: () => {
            router.push('/alterarsenha');
        }
    });    


    console.log('store.state.usuario', store.state.usuario);
    //return store.state.usuario.authorities.some(auth => auth.authority.startsWith(prefixo));

    const usuario = store.state.usuario;
    const listaSistemas = store.state.listaSistemas;

    // Se o Usuario for master, puxa todos os sistemas. Senão, verifica se tem alguma authority que começa com o prefixo do sistema
    const sistemasDisponiveis = listaSistemas.filter(sistema => {
        const usuarioTemPermissao = usuario?.authorities ? usuario.authorities.some(auth => auth.authority.startsWith(sistema.nmcaminhohome.substring(1).toUpperCase())) : false;
        return getUsuarioIsMaster() || usuarioTemPermissao;
    });

    itensMenuUsuario.value.push({
        label: 'Trocar de Sistema',
        icon: 'pi pi-fw pi-sign-out',
        items: sistemasDisponiveis.map(sistema => ({
            label: `${sistema.cdsistema} - ${sistema.nmsistema}`,
            icon: 'pi pi-fw pi-sign-out',
            class: (cdsistema.value == sistema.cdsistema) ? 'font-bold' : '',
            command: () => {
                mudarSistema(sistema.cdsistema);
            }
        }))
    }); 

    itensMenuUsuario.value.push({ separator: true });
    itensMenuUsuario.value.push({
        label: 'Desconectar',
        icon: 'pi pi-fw pi-sign-out',
        command: () => {
            router.push('/logout');
        }
    });    

};

// const itensMenuUsuario = ref([
//     {
//             label: 'Alterar minha senha',
//             icon: 'pi pi-fw pi-lock ',
//             command: () => {
//                 router.push('/alterarsenha');
//             }
//     },
//     {
//         label: 'Trocar de Sistema',
//         icon: 'pi pi-fw pi-sign-out',
//         items: [
//             {
//                 label: '1 - Sautec',
//                 icon: 'pi pi-fw pi-sign-out',
//                 class: (cdsistema.value == 1) ? 'font-bold' : '',    
//                 command: () => {
//                     mudarSistema(1)
//                 }           
//             },
//             {
//                 label: '2 - Financeiro',
//                 icon: 'pi pi-fw pi-sign-out',
//                 class: (cdsistema.value == 2) ? 'font-bold' : '',
//                 command: () => {
//                     mudarSistema(2)
//                 }           
//             },
//             {
//                 label: '3 - Gestor',
//                 icon: 'pi pi-fw pi-sign-out',
//                 class: (cdsistema.value == 3) ? 'font-bold' : '',
//                 command: () => {
//                     mudarSistema(3)
//                 }           
//             },
            
//         ]
//     },
//     {
//         separator: true
//     },
//     {
//         label: 'Desconectar',
//         icon: 'pi pi-fw pi-sign-out',
//         command: () => {
//             router.push('/logout');
//         }
//     }
// ]);



</script>