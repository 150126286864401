<template>
    <ConfirmDialog/>
    <Toast position="top-center"/>
    <router-view />
    <LoadingFullScren v-model:visible="isLoadingVisible" />
</template>

<script setup>
import { provide, ref } from 'vue';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from 'primevue/usetoast';
import LoadingFullScren from '@/components/LoadingFullScren.vue';

const confirm = useConfirm();
const isLoadingVisible = ref(false);
async function getConfirmacao(vMsg) {
    return new Promise((resolve) => {
        confirm.require({
            message: vMsg,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: () => resolve(true),
            reject: () => resolve(false),
        })
    })
}

const toast = useToast();
function getMsgInformacao(vMsg) {
    toast.add({ severity: 'info', summary: 'Informação', detail: vMsg, life: 4000 });
}
function getMsgAtencao(vMsg) {
    toast.add({ severity: 'warn', summary: 'Atenção!', detail: vMsg, life: 4000 });
}
function getMsgErro(vMsg) {
    toast.add({ severity: 'error', summary: 'Erro!', detail: vMsg, life: 4000 });
}

function getMsgSucesso(vMsg) {
    toast.add({ severity: 'success', summary: 'Sucesso!', detail: vMsg, life: 4000 });
}

function setGlobalIsLoading(vVisible) {
    isLoadingVisible.value = vVisible;
}

provide('getMsgInformacao', getMsgInformacao); 
provide('getMsgAtencao', getMsgAtencao);
provide('getMsgErro', getMsgErro);
provide('getMsgSucesso', getMsgSucesso);
provide('getConfirmacao', getConfirmacao);
provide('setGlobalIsLoading', setGlobalIsLoading);


</script>
