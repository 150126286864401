import { createStore } from 'vuex'

//function getUserFromLocalStorage() {    
    //let usuario = JSON.parse(localStorage.getItem('usuario'));
    //console.log('***** usuario ', usuario);   
    //return usuario ? usuario : null;
//}

export default createStore({
    state () {
      return {
        usuario: null,
        listaSistemas: null
        //cdsistema: null
      }
    },
    mutations: {
        setUser(state, usuario) {
            state.usuario = usuario;
        },
        setSistemas(state, listaSistemas) {
            state.listaSistemas = listaSistemas;
        },
        // setSistema(state, cdsistema) {
        //     state.usuario.cdsistema = cdsistema;
        // }
    },   
      
  });

