import api from './api';
import { isNotEmpty } from '@/util'
const urlBase = '/usuarios';

export async function getUsuarios(page, size, sortField, sortOrder, filter) {
    let params = '?';

    filter.forEach(filter => {
        if (isNotEmpty(filter.value)) {
            params += `${filter.filter}=${filter.value}&`;
        }
    });

    if (isNotEmpty(sortField)) {
        params += `sort=${sortField},${sortOrder}&`;
    }

    params += `page=${page + 1}&size=${size}`;
    console.log(params);
    const ret = await api.get(urlBase + params);
    return ret.data;
}

export async function deleteUsuario(id) {
    try {
        const ret = await api.delete(urlBase + '/' + id);
        return ret.data;
    } catch (e) {
        console.log(e);
        throw 'Erro ao excluir um registro';
    }
}

export async function getUsuario(id) {
    const ret = await api.get(urlBase + '/' + id);
    return ret.data;
}

export async function updateUsuario(id, usuario) {
    const ret = await api.put(urlBase + '/' + id, usuario);
    return ret.data;
}

export async function createUsuario(usuario) {
    console.log(usuario);
    const ret = await api.post(urlBase, usuario);
    return ret.data;
}

export async function updateSistemaSelecionado(cdusuario, cdsistema) {
    console.log(cdusuario);
    const ret = await api.post(urlBase + '/updatesistemaselecionado', {
        cdusuario: cdusuario,
        cdsistema: cdsistema
    });
    return ret;
}

export async function updateSenha(senha, novasenha) {
    const ret = await api.post(urlBase + '/updatesenha', {
        senha: senha,
        novasenha: novasenha
    });
    return ret;
}



