import api from './api';
import { isNotEmpty } from '@/util'

export async function getSistemasPagination(page, size, sortField, sortOrder, filter) {
    let params = '?';

    filter.forEach(filter => {
        if (isNotEmpty(filter.value)) {
            params += `${filter.filter}=${filter.value}&`;
        }
    });

    if (isNotEmpty(sortField)) {
        params += `sort=${sortField},${sortOrder}&`;
    }

    params += `page=${page + 1}&size=${size}`;
    const ret = await api.get(`/sistemas/pagination${params}`);
    return ret.data;
}

export async function getSistemas() {
    const ret = await api.get(`/sistemas`);
    return ret.data;
}

export async function getSistema(id) {
    const ret = await api.get('/sistemas/' + id);
    return ret.data;
}

export async function saveSistema(empresa) {
    const ret = await api.post('/sistemas', empresa);
    return ret.data;
}
