import { createRouter, createWebHistory } from 'vue-router';
//import AppLayout from '@/layout/AppLayout.vue';
// eslint-disable-next-line no-unused-vars
import moment from 'moment';
import { getRotas, updateDynamicRoutes } from '@/router/routesManager';
// eslint-disable-next-line no-unused-vars
import { usuarioTemPrivilegio, getSistemaSelecionado, setSistemaSelecionado } from '@/util';
import { getUsuarioToken } from '@/services/auth.service';
import store from '@/store'; 
import { getSistemas } from '@/services/sistemas.service';

//const { proxy } = getCurrentInstance();

// Função para configurar e adicionar as rotas dinâmicas
const setupRouter = async () => {
    const token = JSON.parse(localStorage.getItem('mizunetoken'));
    //console.log(store.state);
    let usuario = store.state.usuario;
    //console.log('setupRouter', usuario, token);
    if (token && !usuario) {
        const usuario = await getUsuarioToken(token);

        // Checar a licença. Se não for um usuario master e não tiver nenhuma licença de uso, removo o token
        //console.log('xxxxxx', usuario);
        const isUsuarioMaster = usuario.authorities.some(a => a.authority == 'MASTER');
        if (!isUsuarioMaster && (usuario.licencas == null || usuario.licencas == undefined || usuario.licencas.length == 0)) {
            localStorage.removeItem('mizunetoken');
        } else {
            const cdsistema = getSistemaSelecionado();
            //console.log('aqui --> ', cdsistema);
            if (cdsistema) {
                const temLicencaAtivaDoSistema = usuario.licencas.some(licenca => licenca.cdsistema === cdsistema);
                if (!temLicencaAtivaDoSistema && !isUsuarioMaster) {
                    setSistemaSelecionado(usuario.licencas[0].cdsistema);
                    //console.log('11111 ', temLicencaAtivaDoSistema, isUsuarioMaster, usuario);
                }
            } else {
                setSistemaSelecionado(usuario.licencas[0].cdsistema);
                //console.log('2222 ', usuario.licencas[0].cdsistema);
            }
        }
        //console.log('ta entrando aqui?', usuario);
        store.commit("setUser", usuario);
        // if (!cdsistema) {
        //     localStorage.setItem('sistemaselecionado', 1);
        // }
    }

    

    

    // Checar se tem sistemas na storage
    let listaSistemas = store.state.sistemas;
    if (!listaSistemas) {
        const retornoListaSistemas = await getSistemas();
        store.commit("setSistemas", retornoListaSistemas);
    }

    //let todasAsRotas = staticRoutes;
    const todasAsRotas = await getRotas();
    //const dynamicRoutes = await getRotas();
    // if (dynamicRoutes) {
    //     todasAsRotas = [...todasAsRotas, ...dynamicRoutes];
    // }
    const router = createRouter({
        history: createWebHistory(),
        routes: todasAsRotas,
        scrollBehavior() {
            return { top: 0 }
        },
    });
    console.log('todasAsRotas', todasAsRotas);

    router.beforeEach(async (to, from, next) => {
        const token = JSON.parse(localStorage.getItem('mizunetoken'));
        if (!token && to.meta.requireAuth && to.name != 'logoutPage' && to.name != 'loginPage') {
            next({ name: 'logoutPage' });
            console.log('xxxx', to, token);
        }
        //let usuario = store.state.usuario;
        // if (token && !usuario) {
        //     console.log('tem token mais nao tem usuario');
        //     const ret = await getUsuarioToken(token);
        //     if (!ret) {
        //         next({ name: 'logoutPage' });
        //         return;
        //     }
        //     usuario = ret;
        //     store.commit("setUser", usuario);
        // }
        //console.log('to', to);
        if (to.meta.requireAuth) {
            //console.log('requireAuth');
            //const token = usuario.token;
            //const dataLogin = moment(usuario.datalogin, "DD/MM/YYYY");
            //const dataHoje = moment();
            //if (!usuario.rememberme && !dataLogin.isSame(dataHoje, 'day')) {
                //next({ name: 'logoutPage' });
                //return;
            //}
        
            if (token && !usuarioTemPrivilegio(token, to.meta.grants)) {
                next({ name: 'accessDenied' });
            }
            
            next();
        } else {
            next();
        }
    });
    return router;
};

const router = await setupRouter();
//console.log('Todas as rotas:', router.getRoutes());
export default router;

// Expondo a função de atualização
export const refreshDynamicRoutes = async () => {
    await updateDynamicRoutes(router);
};