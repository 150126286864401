import api from './api';

export async function getMenus(cdsistema, iscompath = false) {
    const ret = await api.get(`/menus/${cdsistema}?iscompath=${iscompath}`);
    return ret.data;
}

export async function updateMenus(menus) {
    const ret = await api.post('/menus', menus);
    return ret.data;
}

export async function deleteMenu(id) {
    const ret = await api.delete(`/menus/${id}`);
    return ret.data;
}